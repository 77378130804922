import React from 'react';
import PropTypes from 'prop-types';

const CommentCard = ({ comment }) => {
  return (
    <article className="grid grid-cols-6 gap-y-3 mb-8">
      <img src={comment.gravatar} alt="" className="w-12 h-12 rounded-full self-center justify-self-center" />

      <div className="col-span-5">
        <h3 className="font-bold text-sm leading-tight mb-0">{comment.author}</h3>
        <time dateTime={comment.createdAt} className="block text-xs opacity-70">
          {comment.displayCreatedAt}
        </time>
      </div>

      <p className="text-sm col-span-5 col-start-2">{comment.content?.text}</p>
    </article>
  );
};

CommentCard.propTypes = {
  comment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
    gravatar: PropTypes.string.isRequired,
    displayCreatedAt: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    content: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default CommentCard;
