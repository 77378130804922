import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

export const useLatestPosts = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query LatestPostsQuery {
      postsInEnglish: allContentfulBlogPost(
        sort: { fields: createdAt, order: ASC }
        limit: 3
        filter: { node_locale: { eq: "en-US" } }
      ) {
        edges {
          node {
            slug
            title
            content {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 140, truncate: true)
              }
            }
          }
        }
      }

      postsInSpanish: allContentfulBlogPost(
        sort: { fields: createdAt, order: ASC }
        limit: 3
        filter: { node_locale: { eq: "es-MX" } }
      ) {
        edges {
          node {
            slug
            title
            content {
              childMarkdownRemark {
                excerpt(format: PLAIN, pruneLength: 140, truncate: true)
              }
            }
          }
        }
      }
    }
  `);

  const { edges: posts } = intl.locale === 'en' ? data.postsInEnglish : data.postsInSpanish;

  return [...posts];
};
