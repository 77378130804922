import { useIntl } from 'gatsby-plugin-intl';
import React, { useRef, useState } from 'react';

const CommentForm = ({ pageContentfulID, onCommentCreated }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    const elements = [...event.target.elements];
    const formData = elements.reduce((acc, element) => {
      if (element.name) acc[element.name] = element.value;

      return acc;
    }, {});

    fetch('/.netlify/functions/submit-comment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        comment: {
          ...formData,
          pageContentfulID,
        },
      }),
    })
      .then(async (response) => {
        const data = await response.json();

        if (response.ok && data) onCommentCreated(data.comment);
        setIsLoading(false);
        formRef.current.reset();
      })
      .catch(console.error);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="grid grid-cols-2 gap-6 text-sm"
      name="comment"
      method="POST"
      data-netlify="true"
      ref={formRef}
    >
      <input type="hidden" name="form-name" value="comment" />

      <div className="form-field">
        <label>{intl.formatMessage({ id: 'contact-form-name-label' })}</label>
        <input className="text-field" type="text" name="name" />
      </div>

      <div className="form-field">
        <label>{intl.formatMessage({ id: 'contact-form-email-label' })}</label>
        <input className="text-field" type="email" name="email" />
      </div>

      <div className="form-field col-span-2">
        <label>{intl.formatMessage({ id: 'contact-form-comment-label' })}</label>
        <textarea name="message" className="text-field" />
      </div>

      <div className="col-span-2 flex justify-center items-center">
        <button
          type="submit"
          className="submit-btn disabled:cursor-not-allowed disabled:opacity-50"
          disabled={isLoading}
        >
          {intl.formatMessage({ id: 'contact-form-submit' })}
        </button>
      </div>
    </form>
  );
};

export default CommentForm;
