import { graphql, useStaticQuery } from 'gatsby';

export const useContactLinks = () => {
  const data = useStaticQuery(graphql`
    query ContactLinksQuery {
      allContentfulContactLink {
        edges {
          node {
            id
            name
            url
            slug
          }
        }
      }
    }
  `);

  const links = data?.allContentfulContactLink?.edges.reduce(
    (acc, { node: link }) => ({ ...acc, [link.slug]: link }),
    {},
  );

  return links;
};
