import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

const BookIndex = ({ isOpen, chapters, currentPage }) => {
  const intl = useIntl();

  return (
    <nav
      className={`absolute left-0 right-0 w-full px-8 py-4 overflow-y-scroll bg-red-darker rounded-md rounded-b-none text-sm transition-all duration-700 ease-in-out overscroll-contain ${
        isOpen ? 'bottom-24 h-96' : 'bottom-0 h-0 overflow-hidden'
      }`}
      aria-label={intl.formatMessage({ id: 'pagination-index-label' })}
    >
      <ul className="list-none">
        {chapters.map(({ node: chapter }) => (
          <li key={chapter.slug}>
            <span className="block py-6 font-bold md:py-3">{chapter.title}</span>

            <ul>
              {chapter.pages.map((page) => (
                <li className="mb-4 md:mb-2" key={page.slug}>
                  <Link
                    to={`/${intl.locale}/${page.chapter[0].slug}/${page.slug}`}
                    className="relative pl-12 opacity-70 hover:opacity-90"
                    activeClassName="opacity-100"
                  >
                    <div className="absolute left-0 top-1/2 w-6 h-6 transform -translate-y-1/2">
                      <StaticImage
                        src="../images/eye.png"
                        alt=""
                        className={currentPage.slug === page.slug ? 'opacity-100' : 'opacity-0'}
                      />
                    </div>

                    {page.title}
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
};

BookIndex.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  currentPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        pages: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            chapter: PropTypes.arrayOf(
              PropTypes.shape({
                slug: PropTypes.string.isRequired,
              }),
            ),
          }),
        ).isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default BookIndex;
