import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { StaticImage } from 'gatsby-plugin-image';
import { useChapters } from '../hooks/useChapters';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import BookIndex from './BookIndex';

const Pagination = ({ currentPage }) => {
  const intl = useIntl();
  const [isIndexOpen, setIsIndexOpen] = useState(false);
  const chapters = useChapters(intl.locale);
  const pages = Object.values(chapters).reduce((acc, { node: chapter }) => [...acc, ...chapter.pages], []);

  const [{ node: first }] = chapters;
  const [{ node: last }] = [...chapters].reverse();

  const [beginning] = first.pages;
  const [latest] = [...last.pages].reverse();

  const currentPageIndex = pages.findIndex((page) => page.slug === currentPage.slug);
  const previous = currentPageIndex - 1 >= 0 ? pages[currentPageIndex - 1] : null;
  const next = currentPageIndex + 1 < pages.length ? pages[currentPageIndex + 1] : null;

  return (
    <div className="fixed w-11/12 max-w-xl bottom-sa-b-4 left-1/2 transform -translate-x-1/2 md:bottom-8">
      <BookIndex isOpen={isIndexOpen} currentPage={currentPage} chapters={chapters} />

      <nav
        aria-label={intl.formatMessage({ id: 'pagination-label' })}
        className="relative w-full grid grid-cols-5 gap-x-5 justify-center items-center bg-red px-8 py-4 rounded-md"
      >
        <Link
          to={`/${intl.locale}/${beginning.chapter[0].slug}/${beginning.slug}`}
          className={`flex flex-col items-center ${
            beginning.slug === currentPage.slug ? 'pointer-events-none cursor-not-allowed opacity-50' : ''
          }`}
        >
          <span>&laquo;</span>
          <span className="text-xs">{intl.formatMessage({ id: 'pagination-back-to-first' })}</span>
        </Link>

        <Link
          to={`/${intl.locale}/${previous?.chapter[0].slug}/${previous?.slug}`}
          className={`flex flex-col items-center ${
            previous ? '' : 'pointer-events-none cursor-not-allowed opacity-50'
          }`}
        >
          <span>&lt;</span>
          <span className="text-xs">{intl.formatMessage({ id: 'pagination-previous' })}</span>
        </Link>

        <button type="button" onClick={() => setIsIndexOpen(!isIndexOpen)} className="flex flex-col items-center">
          <StaticImage src="../images/eye.png" alt="" className="w-10 h-10 mb-1" />
          <span className="text-xs">{intl.formatMessage({ id: 'pagination-index' })}</span>
        </button>

        <Link
          to={`/${intl.locale}/${next?.chapter[0].slug}/${next?.slug}`}
          className={`flex flex-col items-center ${next ? '' : 'pointer-events-none cursor-not-allowed opacity-50'}`}
        >
          <span>&gt;</span>
          <span className="text-xs">{intl.formatMessage({ id: 'pagination-next' })}</span>
        </Link>

        <Link
          to={`/${intl.locale}/${latest.chapter[0].slug}/${latest.slug}`}
          className={`flex flex-col items-center ${
            latest.slug === currentPage.slug ? 'pointer-events-none cursor-not-allowed opacity-50' : ''
          }`}
        >
          <span>&raquo;</span>
          <span className="text-xs">{intl.formatMessage({ id: 'pagination-go-to-last' })}</span>
        </Link>
      </nav>
    </div>
  );
};

Pagination.propTypes = {
  currentPage: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default Pagination;
